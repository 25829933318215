import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div id="about" className="about-us-section">
            <Container>
                <Row>
                    <Col md={6} className="mb-4" data-aos="fade-right">
                        <h2 className="section-title">О нас</h2>
                        <p className="about-text">
                            Компания <strong>ТЕХМЕТ</strong> более 20 лет занимается металлообработкой любой сложности. Мы
                            специализируемся на создании деталей от прототипов до массового производства. Наша миссия —
                            предоставление высококачественных решений для различных отраслей промышленности.
                        </p>
                        <p className="about-text">
                            В нашем арсенале современное оборудование и команда профессионалов, готовая решать задачи любой
                            сложности. Мы гордимся своей историей и стремимся к постоянному совершенствованию процессов, чтобы
                            соответствовать высоким стандартам наших клиентов.
                        </p>
                    </Col>
                    <Col md={6} className="mb-4" data-aos="fade-left">
                        <h2 className="section-title">Качество и надежность</h2>
                        <p className="about-text">
                            Качество — это наш приоритет. Мы придерживаемся строгих стандартов качества на каждом этапе
                            производства, начиная с выбора материалов и заканчивая конечной проверкой продукции. Все наши изделия
                            проходят тщательную проверку на соответствие техническим требованиям и стандартам безопасности.
                        </p>
                        <p className="about-text">
                            Наша компания сертифицирована по международным стандартам качества, что гарантирует надежность и
                            долговечность нашей продукции. Мы уверены, что качество нашей работы соответствует ожиданиям самых
                            требовательных клиентов.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutUs;
