// src/App.js
import React from 'react';
import Header from './components/Header';
import Slogan from './components/Slogan';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import ContactForm from './components/ContactForm';
import WhyChooseUs from './components/WhyChooseUs';
import ProductionGallery from './components/ProductionGallery';
import ContactInfo from './components/ContactInfo';
import Footer from './components/Footer';
import './App.css'; // Убедитесь, что ваш CSS файл подключен
import BackToTopButton from './components/BackToTopButton';

function App() {
    return (
        <div className="main-container">
            <Header />
            <Slogan />
            <Services />
            <AboutUs />
            <ContactForm />
            <WhyChooseUs />
            <ProductionGallery />
            <ContactInfo />
            <Footer />
            <BackToTopButton />
        </div>
    );
}

export default App;
