import React from 'react';
import { Container } from 'react-bootstrap';
import './Slogan.css';

const Slogan = () => {
    return (
        <div id="slogan" className="slogan-section">
            <Container>
                <h1 className="slogan-text">
                    Металлообработка любой сложности, <br />
                    от прототипа - до массового производства.
                </h1>
            </Container>
        </div>
    );
};

export default Slogan;
