// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-content">
                <p className="footer-text">
                    ИП "Мякишев Олег Витальевич", 196128, г. Санкт-Петербург, ул. Варшавская 5АБВ
                </p>
                <p className="footer-text">
                    ИНН 181302309760 ОГРН 319183200068612
                </p>
                <p className="footer-text">
                    &copy; {new Date().getFullYear()} ТЕХМЕТ. Все права защищены.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
