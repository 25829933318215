// src/components/ProductionGallery.js
import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import './ProductionGallery.css';

import img1 from '../assets/images/1.jpg';
import img2 from '../assets/images/2.jpg';
import img3 from '../assets/images/3.jpg';
import img4 from '../assets/images/4.jpg';
import img5 from '../assets/images/5.jpg';
import img6 from '../assets/images/6.jpg';
import img7 from '../assets/images/7.jpg';
import img8 from '../assets/images/8.jpg';
import img9 from '../assets/images/9.jpg';
import img11 from '../assets/images/11.jpg';


const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img11,
];

const ProductionGallery = () => {
    return (
        <div id="production" className="production-gallery-section">
            <Container>
                <h2 className="section-title">Наше производство</h2>
                <Carousel>
                    {images.map((image, index) => (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={image}
                                alt={`Слайд ${index + 1}`}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        </div>
    );
};

export default ProductionGallery;
