// src/components/ContactInfo.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ContactInfo.css';

const ContactInfo = () => {
    return (
        <div id="contact" className="contact-info-section">
            <Container>
                <Row className="mb-4">
                    <Col md={6} className="mb-4">
                        <h2 className="section-title">Контактная информация</h2>
                        <div className="contact-details">
                            <div className="contact-item">
                                <h3 className="contact-item-title">Адрес:</h3>
                                <p className="contact-item-text">196128, г. Санкт-Петербург, ул. Варшавская 5АБВ</p>
                            </div>
                            <div className="contact-item">
                                <h3 className="contact-item-title">Телефон:</h3>
                                <p className="contact-item-text">
                                    <a href="tel:+78123456789" className="contact-link">+7 (931) 361-62-64</a>
                                </p>
                            </div>
                            <div className="contact-item">
                                <h3 className="contact-item-title">Email:</h3>
                                <p className="contact-item-text">
                                    <a href="mailto:texmetspb@yandex.by" className="contact-link">texmetspb@yandex.by</a>
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="mb-4">
                        <h2 className="section-title">Наше местоположение</h2>
                        <div className="map-wrapper">
                            <iframe
                                src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=232048956762"
                                width="100%"
                                height="400"
                                frameBorder="0"
                                allowFullScreen
                                title="Map"
                            ></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContactInfo;
