import React from 'react';
import { Container, Card } from 'react-bootstrap';
import Slider from 'react-slick';
import './Services.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const services = [
    { title: 'Электроэрозионная обработка', description: 'Высокоточная обработка металлов методом электроэрозии, позволяющая создавать сложные формы и детали с минимальными допусками.' },
    { title: 'Ремонт редукторов', description: 'Профессиональный ремонт и обслуживание редукторов всех типов.' },
    { title: 'Токарная обработка', description: 'Токарные работы любой сложности на современном оборудовании.' },
    { title: 'Фрезерная обработка', description: 'Высокоточная фрезеровка металлов и других материалов.' },
    { title: 'Сварочные работы', description: 'Качественные сварочные работы с соблюдением всех стандартов.' },
    { title: 'Обработка отверстий', description: 'Обработка отверстий с использованием методов сверления, зенкерования, цекования и развертывания.' },
    { title: 'Зуборезные работы', description: 'Выполнение зуборезных операций для создания и восстановления зубчатых передач.' },
    { title: 'Шлифовальные работы', description: 'Шлифовка поверхностей для достижения высокой точности и гладкости обработки.' },
];

const Services = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div id="services" className="services-section">
            <Container>
                <h2 className="section-title" data-aos="fade-up">Наши услуги</h2>
                <Slider {...settings} className="services-slider">
                    {services.map((service, index) => (
                        <div key={index} className="service-slider-item">
                            <Card className="service-card h-100">
                                <Card.Body>
                                    <Card.Title className="service-card-title">{service.title}</Card.Title>
                                    <Card.Text>{service.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                </Slider>
            </Container>
        </div>
    );
};

export default Services;
