import React, { useState } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import 'react-phone-number-input/style.css';
import './ContactForm.css';

const ContactForm = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div id="contact-form" className="contact-form-section">
            <Container>
                <div className="info-block">
                    <h2 className="section-title-contact-form">Свяжитесь с нами</h2>
                    <p className="contact-description">
                        Мы готовы ответить на все ваши вопросы и помочь вам выбрать лучший вариант сотрудничества.
                        Нажмите на кнопку ниже, чтобы выбрать удобный для вас мессенджер и оставить заявку.
                    </p>
                    <Button variant="primary" onClick={handleShow}>
                        Оставить заявку
                    </Button>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Выберите мессенджер</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Напишите в любой удобный мессенджер:</p>
                        <div className="contact-icons">
                            <a href="https://t.me/Oleg88890" target="_blank" rel="noopener noreferrer"
                               className="contact-icon">
                                <FaTelegramPlane size={24}/> Telegram
                            </a>
                            <a href="https://wa.me/79522813248" target="_blank" rel="noopener noreferrer"
                               className="contact-icon">
                                <FaWhatsapp size={24}/> WhatsApp
                            </a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Закрыть
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
};

export default ContactForm;
