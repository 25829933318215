import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Header.css';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [expanded, setExpanded] = useState(false); // Добавляем состояние для управления раскрытием меню

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLinkClick = (event, targetId) => {
        event.preventDefault();
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
        setExpanded(false); // Закрываем мобильное меню после нажатия на ссылку
    };

    return (
        <Navbar
            bg="dark"
            variant="dark"
            expand="lg"
            className={`header-navbar ${isScrolled ? 'scrolled' : ''}`}
            expanded={expanded} // Управляем раскрытием меню
        >
            <Container>
                <Navbar.Brand href="#slogan" className="header-brand">ТЕХМЕТ</Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setExpanded(expanded ? false : true)} // Переключаем состояние меню при клике на Toggle
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link
                            href="#services"
                            onClick={(e) => handleLinkClick(e, 'services')}
                        >
                            Услуги
                        </Nav.Link>
                        <Nav.Link
                            href="#about"
                            onClick={(e) => handleLinkClick(e, 'about')}
                        >
                            О нас
                        </Nav.Link>
                        <Nav.Link
                            href="#production"
                            onClick={(e) => handleLinkClick(e, 'production')}
                        >
                            Производство
                        </Nav.Link>
                        <Nav.Link
                            href="#contact"
                            onClick={(e) => handleLinkClick(e, 'contact')}
                        >
                            Контакты
                        </Nav.Link>
                        <Nav.Link
                            href="#contact-form"
                            onClick={(e) => handleLinkClick(e, 'contact-form')}
                            className="highlight-button"
                        >
                            Оставить заявку
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
