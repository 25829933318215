// src/components/WhyChooseUs.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaBriefcase, FaCogs, FaUser, FaCertificate } from 'react-icons/fa';
import './WhyChooseUs.css';

const reasons = [
    {
        title: 'Опыт и профессионализм',
        description: 'Мы работаем в отрасли более 20 лет, предоставляя клиентам высококачественные решения.',
        icon: <FaBriefcase size={40} className="reason-icon" />,
        aos: 'fade-up'
    },
    {
        title: 'Современное оборудование',
        description: 'Используем новейшие технологии и оборудование для достижения наилучших результатов.',
        icon: <FaCogs size={40} className="reason-icon" />,
        aos: 'fade-up'
    },
    {
        title: 'Индивидуальный подход',
        description: 'Работаем над каждым проектом с учетом особенностей и требований клиента.',
        icon: <FaUser size={40} className="reason-icon" />,
        aos: 'fade-up'
    },
    {
        title: 'Гарантия качества',
        description: 'Все изделия проходят строгий контроль качества и соответствуют международным стандартам.',
        icon: <FaCertificate size={40} className="reason-icon" />,
        aos: 'fade-up'
    },
];

const WhyChooseUs = () => {
    return (
        <div id="why-choose-us" className="why-choose-us-section">
            <Container>
                <h2 className="section-title" data-aos="fade-up">Почему стоит выбрать «ТЕХМЕТСПБ»?</h2>
                <Row>
                    {reasons.map((reason, index) => (
                        <Col md={3} sm={6} key={index} className="mb-4">
                            <Card className="reason-card" data-aos={reason.aos}>
                                <Card.Body>
                                    <div className="reason-icon-container">
                                        {reason.icon}
                                    </div>
                                    <Card.Title className="reason-title">{reason.title}</Card.Title>
                                    <Card.Text>{reason.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default WhyChooseUs;
